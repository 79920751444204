import MpApiClient from "./httpClient";
import v3Client from "./v3Client";

// PUT exclusive domain pixel config data
export const putDomainPixel = async (encryptedToken, offer_id, data) => {
  const auth = {
    headers: {Authorization: `Bearer ${encryptedToken}`}
  };
  try {
    const {data: response} = await MpApiClient.put(`/${encryptedToken}/offers/${offer_id}/configs`, data, auth)
    if (response.statusCode == 200) {
      console.log("API Status: ", response.statusCode);
      return true;
    } else {
      console.error("API Error: ", response.statusCode);
      return false;
    }
  } catch (error) {
    console.error(error.message);
  }
};

// POST DNS Record
export const postDnsRecord = async (encryptedToken, data) => {
  const auth = {
    headers: {Authorization: `Bearer ${encryptedToken}`}
  };
  try {
    const {data: response} = await MpApiClient.post('/exclusive-domain-verification', data, auth)
    if (response.success) {
      console.log("API Status: ", response);
      return true;
    } else {
      console.error("API Error: ", response);
      return false;
    }
  } catch (error) {
    console.error(error.response.data.message);
    const data = {};
    data.error = error.response.status;
    data.message = error.response.data.message;
    return data;
  }
};

// POST New Exclusive Domain
export const postNewDomain = async (encryptedToken, offer_id, data) => {
  const auth = {
    headers: {Authorization: `Bearer ${encryptedToken}`}
  };
  try {
    // First, check if the offer is v3
    const is_v3_response = await v3Client.get(`/everflow/offers/${offer_id}/`, data,  auth);
    if (is_v3_response.status === 200) {
      console.log("API Status: ", is_v3_response);
      let is_v3 = is_v3_response.data.is_v3;

      // Branch logic based on the is_v3 flag
      is_v3 = false;

      if (is_v3) {
        const payload = {
          brand_name: data.brandName,
          offer_id: offer_id,
          core_domain: data.exclusiveDomain,
          domain_prefix: data.domainPrefix
        };
        // If is_v3 is true, make requests to Django api
        const {data: v3Response} = await v3Client.post(`/everflow/exclusive-domains/${encryptedToken}/`, payload, auth);
        if (v3Response.success) {
          console.log("V3 API Status: ", v3Response);
          return { success: true, domain_uid: v3Response.domain_uid };
        } else {
          console.error("V3 API Error: ", v3Response);
          return { success: true, domain_uid: v3Response.domain_uid };
        }
      } else {
        // Remove the exclusiveDomain and domainPrefix keys since they aren't used in the old platform
        delete data.exclusiveDomain;
        delete data.domainPrefix;

        // If is_v3 is false, proceed with the original request
        const {data: response} = await MpApiClient.post('/exclusive-domain-registration', data, auth);
        if (response.success) {
          console.log("API Status: ", response);
          return { success: true };
        } else {
          console.error("API Error: ", response);
          return { success: false };
        }
      }
    } else {
      console.error("API Error: ", is_v3_response);
      return false;
    }
  } catch(error) {
    console.error(error.message);
    const errorData = {};
    if (error.response) {
      errorData.error = error.response.status;
    } else {
      errorData.error = "Unknown error occurred.";
    }
    return errorData;
  }
};