/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react";
import {Button, Dropdown, DropdownButton, Modal} from "react-bootstrap";
import {platforms} from "./PixelConfig/platforms"
import PropTypes from "prop-types";
import {PixelConfig} from "./PixelConfig";
import {AddDomain} from "./AddDomain";
import {VerifyDomain} from "./VerifyDomain";


const Configurator = ({
  withBrandName,
  addDomainTo,
  withDomainType = "exclusive",
  withDomain,
  withDomainData,
  data,
  refreshData,
  showNotification,
  showPendingDomain
}) => {

  // Working domain
  const [workingDomain, setWorkingDomain] = useState("");

  // Wait indicator
  const [waitMsg, setWaitMsg] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  // Modal handling
  const [showModal, setShowModal] = useState(false);
  const onCloseModal = () => {
    if(withDomain){
      setWorkingDomain(withDomain);
    }else{
      setWorkingDomain("");
    }
    if(action === "add" && workingDomain !== ""){
      refreshData();
    }
    setShowModal(false);
    if (typeof showPendingDomain !== "undefined") {
      showPendingDomain('');
    }
    setTimeout(function(){
      setWaitMsg("");
      setShowSpinner(false);
   	}, 1000);
  }

  // Display action button
  const DisplayActionButton = () => {
    const Content = [];
    if(addDomainTo !== undefined){
      if(addDomainTo !== ""){
        Content.push(
          <Button
            key="0"
            variant="action"
            className="add-domain-btn"
            value={addDomainTo}
            onClick={(e) => {
              setAction("add");
              setShowModal(true);
            }}
          >
            Add Exclusive Domain
          </Button>
        );
      }else{
        return null;
      }
    }else{
      // Pixel actions to display
      let actions = {};
      platforms.forEach(function (platform) {
        if (platform.action === "update") {
          actions[platform.brand] = platform.config;
        }
      });
      Content.push(
        <DropdownButton
          key="0"
          className="configure-btn"
          title="Configure Pixels"
          onSelect={(pixel) => {
            setPlatform(pixel);
            setAction("configure");
            setShowModal(true);
          }}
        >
          {Object.keys(actions).map(function (key, index) {
            if (withDomainType === "shared" && key === "Facebook") {
              return <Dropdown.Item as="button" key={index} eventKey={actions[key] + "-disabled"}>{key}</Dropdown.Item>;
            } else {
              return <Dropdown.Item as="button" key={index} eventKey={actions[key]}>{key}</Dropdown.Item>;
            }
          })}
          <Dropdown.Item as="button" eventKey="other">Other Ad Platforms</Dropdown.Item>
          <Dropdown.Divider/>
          <Dropdown.Item as="button" eventKey="clear">Delete Configurations</Dropdown.Item>
        </DropdownButton>
      );
    }
    return Content;
  }

  // Configuration pane content
  const [action, setAction] = useState("");
  const [platform, setPlatform] = useState("");
  const [offerId, setOfferId] = useState("");
  const [verifyComplete, setVerifyComplete] = useState(false);
  const Configuration = (action) => {
    if (action === "add") {
      return (
        <AddDomain
          data={data}
          withBrandName={withBrandName}
          addDomainTo={addDomainTo}
          createDomain={onCreate}
          configureDomain={onConfigure}
          closeModal={onCloseModal}
          waitIndicator={onWait}
          previewDomain={onPendingDomain}
          showNotification={showNotification}
          refreshData={onRefreshData}
        />
      );
    }else if (action === "configure") {
      return (
        <PixelConfig
          data={data}
          withDomain={workingDomain}
          withDomainType={withDomainType}
          withDomainData={withDomainData}
          withBrandName={withBrandName}
          withOfferId={offerId}
          withPixel={platform}
          withVerification={verifyComplete}
          configureDomain={onConfigure}
          closeModal={onCloseModal}
          waitIndicator={onWait}
          showNotification={showNotification}
          refreshData={onRefreshData}
        />
      );
    } else if (action === "verify") {
      return (
        <VerifyDomain
          withDomain={workingDomain}
          withOfferId={offerId}
          withPixel={platform}
          configureDomain={onConfigure}
          closeModal={onCloseModal}
          waitIndicator={onWait}
          showNotification={showNotification}
          refreshData={onRefreshData}
        />
      );
    }
  }

  // Actions
  const onCreate = (domain) => {
    setWorkingDomain(domain);
  }
  const onConfigure = (pixel, offer, needVerify, verified = false) => {
    if(offerId === ""){
      setOfferId(offer);
    }
    if(verified) {
      setVerifyComplete(true);
      setPlatform(pixel);
      setAction("configure");
    }else{
      if (needVerify) {
        setAction("verify");
      } else {
        setPlatform(pixel);
        setAction("configure");
      }
    }
  }
  const onRefreshData = () => {
    refreshData();
  }

  let processTimer;
  const onWait = (message, noMessage = false) => {
    clearTimeout(processTimer);
    if(noMessage){
      setWaitMsg("");
      setShowSpinner(true);
    }else if(message !== ""){
      setWaitMsg(message);
      setShowSpinner(true);
      processTimer = setTimeout(function () {
        console.log('Process is taking >30 seconds.');
        setWaitMsg(message + ".. This may take a few minutes.");
      }, 30000);
    }else{
      setWaitMsg("");
      setShowSpinner(false);
    }
  }
  const onPendingDomain = (display) => {
    if(display){
      showPendingDomain(workingDomain);
    }
  }

  // Effects
  useEffect(() => {
    setWaitMsg("");
    setOfferId("");
    setShowSpinner(false);
    if (addDomainTo !== undefined && addDomainTo !== "") {
      setAction("add");
    } else if (withDomain !== undefined && withDomain !== "") {
      setAction("configure");
      setWorkingDomain(withDomain);
    }
  }, []);

  return (
    <>
      <DisplayActionButton/>
      <Modal
        className={showSpinner ? "loading-state modal-configure" : "modal-configure"}
        show={showModal}
        onHide={onCloseModal}
        backdrop="static"
        centered
      >
        <Modal.Body
          data-configuration-phase={action}
        >
          <button type="button" className="close" onClick={onCloseModal}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
          {Configuration(action)}
        </Modal.Body>
        {showSpinner &&
          <>
            <div className="loading">
              <div className="spinner"></div>
            </div>
            <div className="message">
              {waitMsg}
            </div>
          </>
        }
      </Modal>
    </>
  );
};

Configurator.propTypes = {
  withBrandName: PropTypes.string,
  addDomainTo: PropTypes.string,
  withDomainType: PropTypes.string,
  withDomain: PropTypes.string,
  withDomainData: PropTypes.object,
  refreshData: PropTypes.func,
  data: PropTypes.object,
  showNotification: PropTypes.func,
  showPendingDomain: PropTypes.func,
};
export default Configurator;