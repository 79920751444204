/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import DomainPanel from "./DomainPanel";
import PropTypes from "prop-types";

const DomainListPanels = ({
  showNotification,
  refreshData,
  data
}) => {

  // Exclusive domains
  const ExclusiveDomains = () => {
    if((data.exDomains).length){
      const Content = [];
      (data.exDomains).forEach(function (exclusive, index) {
        Content.push(
          <DomainPanel
            brandName={exclusive.name}
            key={index}
            baseUrl={exclusive.url}
            domainType="exclusive"
            showNotification={showNotification}
            initOpen={true}
            domains={exclusive.domains}
            data={data}
            refreshData={refreshData}
          />
        );
      });
      return Content;
    }else{
      return (
        <DomainPanel
          brandName=""
          baseUrl=""
          domainType="exclusive"
          showNotification={false}
          initOpen={true}
          domains={[]}
          data={[]}
          refreshData={refreshData}
        />
      );
    }
  }

  // Shared domains
  const [sharedOpen, setSharedOpen] = useState(false);
  const SharedDomains = () => {
    if(data.sharedDomains.length > 0) {
      return (
        <DomainPanel
          brandName=""
          baseUrl=""
          domains={data.sharedDomains}
          domainType="shared"
          showNotification={showNotification}
          initOpen={data.mpBrands.length === 0}
          data={data}
          refreshData={refreshData}
        />
      );
    }else{
      return null;
    }
  }

  // Effects
  useEffect(() => {
    setSharedOpen(data.mpBrands.length === 0);
  }, []);

  return (
    <>
      {ExclusiveDomains()}
      {SharedDomains()}
    </>
  );
};

DomainListPanels.propTypes = {
  showNotification: PropTypes.func,
  refreshData: PropTypes.func,
  data: PropTypes.object,
};

export default DomainListPanels;
